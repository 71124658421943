import { gql } from '@apollo/client';
import { FeeType } from 'shared/models/fee';

import { InvoicePaymentStatus, InvoiceStatus } from 'shared/models/invoice';

export interface BaseFee {
    __typename?: 'fees';
    id: string;
    type: FeeType;
    amount: number;
    incurredAt: number;
    clientId: string;
    description?: string;
    dueAt?: number;
    salary?: number;
    signOnBonus?: number;
    recruiterCommission?: number;
    accountManagerId?: string;
    recruiterId?: string;
    accountManagerCommission?: number;
    guaranteeCompletionAt?: number;
    appliedToFeeId?: string;
    personId?: string;
    jobId?: string;
    createdAt?: number;
}

export interface Fee extends BaseFee {
    person?: {
        id: string;
        name: string;
    };
    job?: {
        id: string;
        title: string;
        accountManager: {
            id: string;
            name: string;
        };
    };
    client: {
        id: string;
        name: string;
    };
    recruiter: {
        id: string;
        name: string;
    };
    accountManager: {
        id: string;
        name: string;
    };
    invoices: Invoice[];
}
export interface PlacementFee extends Fee {
    candidate: {
        scores: Array<{
            id: string;
            score: number;
            createdAt: number;
            searchProfilesScoreId: string | null;
        }>;
    };
}
export interface InvoiceLineItem {
    id: string;
    description: string;
    amount: number;
    price: number;
    quantity: number;
    feeId: string;
    refunded: boolean;
    fee?: Fee;
}

export interface InvoicePayment {
    id: string;
    amount: number;
    date: number;
    status: InvoicePaymentStatus;
}

export interface InvoiceCustomer {
    id: string;
    name: string;
    contact: { firstName: string; lastName: string };
    email: string;
    clientId: string;
    client: {
        id: string;
        name: string;
    };
}

export interface InvoiceSend {
    id: string;
    sentAt: number;
    requestedAt: number;
    requestedBy: string;
}

export interface BankTransaction {
    id: string;
    amount: number;
    transaction: {
        id: string;
        permaLink: string;
        externalMemo: string;
        ignoreExcessAmount: boolean;
        bankInternalMemo: string;
        amount: number;
        postedAt: number;
        account: {
            id: string;
            name: string;
        };
    };
}

export interface Invoice {
    id: string;
    totalAmount: number;
    status: InvoiceStatus;
    invoiceNumber: string;
    invoiceDate: number;
    archived: boolean;
    dueDate: number;
    pdf: string;
    payments: InvoicePayment[];
    customer: InvoiceCustomer;
    lineItems: InvoiceLineItem[];
    sends: InvoiceSend[];
    bankTransactions: BankTransaction[];
}

export interface Customer {
    id: string;
    name: string;
    companyName: string;
    contact: { firstName: string; lastName: string };
    email: string;
}

export interface ClientInvoiceData {
    id: string;
    invoiceTo: string;
    invoiceCc: string[];
    invoiceFollowUpContacts: string[];
    sendAutoBillComReminders: boolean;
    defaultPONumber: string;
    paymentTermDays: number;
    accountManager: {
        id: string;
        email: string;
    };
}

export interface ClientFeeData {
    id: string;
    name: string;
    accountManager: {
        id: string;
        email: string;
    };
}

export const feeFragment = gql`
    fragment FeeFragment on fees {
        id
        amount
        type
        salary
        signOnBonus
        description
        dueAt
        incurredAt
        recruiterCommission
        accountManagerCommission
        guaranteeCompletionAt
        appliedToFeeId
        personId
        jobId
        createdAt
        clientId
        client {
            id
            name
        }
        person {
            id
            name(path: "full")
        }
        job {
            id
            title
            accountManager: userByAccountmanagerid {
                id
                name(path: "full")
            }
        }
        accountManager {
            id
            name(path: "full")
        }
        recruiter {
            id
            name(path: "full")
        }
    }
`;

export const invoiceFragment = gql`
    fragment InvoiceFragment on invoices {
        id
        totalAmount
        status
        invoiceNumber
        invoiceDate
        archived
        dueDate
        pdf
        lineItems {
            id
            description
            amount
            price
            quantity
            feeId
            refunded
        }
        bankTransactions {
            id
            amount
            transaction {
                id
                permaLink
                ignoreExcessAmount
                externalMemo
                bankInternalMemo
                amount
                postedAt
                account {
                    id
                    name
                }
            }
        }
        payments {
            id
            amount
            date
            status
        }
        customer {
            id
            name
            contact
            email
            clientId
            client {
                id
                name
            }
        }
        sends {
            id
            sentAt
            requestedAt
            requestedBy
        }
    }
`;

export const BANK_ACCOUNTS = gql`
    query BankAccounts {
        bankAccounts: bank_accounts(where: { canUploadTransactions: { _eq: true } }) {
            id
            name
        }
    }
`;

export const CANDIDATE_COMMISSION_PERCENTAGES = gql`
    query CandidateCommissionPercentages($personId: String!, $jobId: String!) {
        percentages: candidates_by_pk(personId: $personId, jobId: $jobId) {
            _id
            recruiterPercentage
            accountManagerPercentage
        }
    }
`;

export const PLACEMENT_FEES = gql`
    ${feeFragment}
    ${invoiceFragment}
    query PlacementFees($personId: String!, $jobId: String!) {
        fees(
            where: {
                personId: { _eq: $personId }
                jobId: { _eq: $jobId }
                type: { _in: ["placement", "monthly-contractor"] }
            }
        ) {
            ...FeeFragment
            invoices {
                ...InvoiceFragment
            }
        }
    }
`;

export const UPDATE_PLACEMENT_FEE = gql`
    mutation UpdatePlacementFee($id: String!, $data: fees_set_input!) {
        update_fees_by_pk(pk_columns: { id: $id }, _set: $data) {
            id
        }
    }
`;

export const CREATE_PLACEMENT_FEE = gql`
    mutation CreatePlacementFee($data: fees_insert_input!) {
        insert_fees_one(object: $data) {
            id
        }
    }
`;

export const JOB_FEES = gql`
    ${invoiceFragment}
    ${feeFragment}
    query JobFees($jobId: String!) {
        fees(where: { jobId: { _eq: $jobId } }, order_by: { incurredAt: desc }) {
            ...FeeFragment
            invoices {
                ...InvoiceFragment
            }
        }
    }
`;

export const CREATE_FEE = gql`
    mutation CreateFee($data: fees_insert_input!) {
        insert_fees_one(object: $data) {
            id
        }
    }
`;

export const EDIT_JOB_FEE = gql`
    mutation EditJobFee($id: String!, $data: fees_set_input!) {
        update_fees_by_pk(pk_columns: { id: $id }, _set: $data) {
            id
        }
    }
`;

export const DELETE_JOB_FEE = gql`
    mutation DeleteJobFee($id: String!) {
        delete_fees_by_pk(id: $id) {
            id
        }
    }
`;

export const CLIENT_INVOICES = gql`
    ${feeFragment}
    ${invoiceFragment}
    query ClientInvoices($clientId: String!) {
        invoices(where: { customer: { client: { id: { _eq: $clientId } } } }, order_by: { invoiceDate: desc }) {
            ...InvoiceFragment
            lineItems {
                fee {
                    ...FeeFragment
                }
            }
        }
    }
`;

export const CREATE_CLIENT_FEE_DATA = gql`
    query CreateClientFeeData($clientId: String!) {
        client: clients_by_pk(id: $clientId) {
            id
            name
            accountManager {
                id
                email
            }
        }
    }
`;

export const CLIENT_FEES = gql`
    ${feeFragment}
    ${invoiceFragment}
    query ClientFees($clientId: String!) {
        fees(where: { clientId: { _eq: $clientId } }, order_by: { incurredAt: desc }) {
            ...FeeFragment
            invoices {
                ...InvoiceFragment
            }
        }
    }
`;

export const CLIENT_CUSTOMERS = gql`
    query ClientCustomers($clientId: String!) {
        customers: invoice_customers(
            where: { clientId: { _eq: $clientId }, archived: { _eq: false }, ignored: { _eq: false } }
            order_by: { primary: desc }
        ) {
            id
            name
            companyName
            contact
            email
        }
    }
`;

export const FEE_DETAILS = gql`
    ${feeFragment}
    ${invoiceFragment}
    query FeeDetails($id: String!) {
        fee: fees_by_pk(id: $id) {
            ...FeeFragment
            invoices {
                ...InvoiceFragment
            }
        }
    }
`;

export const INVOICE_DETAILS = gql`
    ${invoiceFragment}
    ${feeFragment}
    query InvoiceDetails($id: String!) {
        invoice: invoices_by_pk(id: $id) {
            ...InvoiceFragment
            lineItems {
                fee {
                    ...FeeFragment
                }
            }
        }
    }
`;

export const APPLY_FEE_TO_LINE_ITEM = gql`
    mutation ApplyFeeToLineItem($id: String!, $data: invoice_line_items_set_input!) {
        updatedLineItem: update_invoice_line_items_by_pk(pk_columns: { id: $id }, _set: $data) {
            id
            feeId
        }
    }
`;

export const FEES = gql`
    ${feeFragment}
    ${invoiceFragment}
    query Fees($jobType: [String!], $startTime: bigint!, $endTime: bigint!, $excludeClientIds: [String!]) {
        fees(
            where: {
                _or: [{ job: { jobType: { _in: $jobType } } }, { jobId: { _is_null: true } }]
                client: { id: { _nin: $excludeClientIds } }
                incurredAt: { _gte: $startTime, _lte: $endTime }
                appliedToFeeId: { _is_null: true }
            }
            order_by: { incurredAt: desc }
        ) {
            ...FeeFragment
            invoices {
                ...InvoiceFragment
            }
        }
    }
`;

export const REVENUE = gql`
    ${feeFragment}
    ${invoiceFragment}
    query Revenue($jobType: [String!], $startTime: bigint!, $endTime: bigint!, $excludeClientIds: [String!]) {
        fees(
            where: {
                _and: [
                    { client: { id: { _nin: $excludeClientIds } } }
                    { appliedToFeeId: { _is_null: true } }
                    {
                        _or: [
                            { type: { _neq: "placement" }, incurredAt: { _gte: $startTime, _lte: $endTime } }
                            { type: { _eq: "placement" }, dueAt: { _gte: $startTime, _lte: $endTime } }
                        ]
                    }
                    { _or: [{ job: { jobType: { _in: $jobType } } }, { jobId: { _is_null: true } }] }
                ]
            }
            order_by: { incurredAt: desc }
        ) {
            ...FeeFragment
            invoices {
                ...InvoiceFragment
            }
        }
    }
`;

export const INVOICES = gql`
    ${invoiceFragment}
    ${feeFragment}
    query Invoices($jobType: [String!], $startTime: bigint!, $endTime: bigint!, $excludeClientIds: [String!]) {
        invoices(
            where: {
                archived: { _eq: false }
                invoiceDate: { _gte: $startTime, _lte: $endTime }
                customer: { client: { id: { _nin: $excludeClientIds } }, isStaffingCustomer: { _eq: false } }
                _or: [
                    { lineItems: { feeId: { _is_null: true } } }
                    { lineItems: { fee: { jobId: { _is_null: true } } } }
                    { lineItems: { fee: { job: { jobType: { _in: $jobType } } } } }
                ]
            }
            order_by: { invoiceDate: desc }
        ) {
            ...InvoiceFragment
            lineItems {
                fee {
                    ...FeeFragment
                }
            }
        }
    }
`;

export const MONTHLY_RPO_FEES = gql`
    ${feeFragment}
    ${invoiceFragment}
    query MonthlyRPOFees($jobType: [String!], $startTime: bigint!, $endTime: bigint!, $excludeClientIds: [String!]) {
        fees(
            where: {
                type: { _eq: "monthly-rpo" }
                client: { id: { _nin: $excludeClientIds } }
                incurredAt: { _gte: $startTime, _lte: $endTime }
                _or: [{ job: { jobType: { _in: $jobType } } }, { jobId: { _is_null: true } }]
            }
            order_by: { incurredAt: desc }
        ) {
            ...FeeFragment
            invoices {
                ...InvoiceFragment
            }
        }
    }
`;

export const PLACEMENTS = gql`
    ${feeFragment}
    ${invoiceFragment}
    query Placements($jobType: [String!], $startTime: bigint!, $endTime: bigint!, $excludeClientIds: [String!]) {
        fees(
            where: {
                type: { _in: ["placement", "monthly-contractor"] }
                client: { id: { _nin: $excludeClientIds } }
                incurredAt: { _gte: $startTime, _lte: $endTime }
                _or: [{ job: { jobType: { _in: $jobType } } }, { jobId: { _is_null: true } }]
            }
            order_by: { incurredAt: desc }
        ) {
            ...FeeFragment
            invoices {
                ...InvoiceFragment
            }
            candidate {
                scores(order_by: { createdAt: desc }) {
                    id
                    score
                    createdAt
                    searchProfilesScoreId
                }
            }
        }
    }
`;

export const SAVE_BILL_CUSTOMER = gql`
    mutation SaveBillCustomer($customer: BillCustomerData!, $clientId: String!) {
        saveBillCustomer(customer: $customer, clientId: $clientId) {
            id
        }
    }
`;

export const CLIENT_INVOICE_DATA = gql`
    query ClientInvoiceData($id: String!) {
        client: clients_by_pk(id: $id) {
            id
            invoiceTo
            invoiceCc
            invoiceFollowUpContacts
            sendAutoBillComReminders
            defaultPONumber
            paymentTermDays
            accountManager {
                id
                email
            }
        }
    }
`;

export const CREATE_INVOICE = gql`
    mutation CreateInvoice($data: InvoiceCreateInput!) {
        createInvoice(data: $data) {
            id
            pdf
            error
        }
    }
`;

export const DELETE_INVOICE = gql`
    mutation DeleteInvoice($id: String!) {
        deleteInvoice(id: $id)
    }
`;

export const APPLY_PREPAYMENT_FEE = gql`
    mutation ApplyPrepaymentFee($finalFeeId: String, $prepaymentFeeId: String!) {
        update_fees_by_pk(pk_columns: { id: $prepaymentFeeId }, _set: { appliedToFeeId: $finalFeeId }) {
            id
        }
        update_invoice_line_items(where: { feeId: { _eq: $prepaymentFeeId } }, _set: { feeId: $finalFeeId }) {
            affected_rows
        }
    }
`;

export const SEND_INVOICE = gql`
    mutation SendInvoice($invoiceId: String!, $sendAt: bigint!, $invoiceTo: String, $invoiceCc: jsonb) {
        insert_invoice_sends_one(
            object: { invoiceId: $invoiceId, requestedAt: $sendAt, invoiceTo: $invoiceTo, invoiceCc: $invoiceCc }
        ) {
            id
        }
    }
`;

export const DELETE_INVOICE_SEND = gql`
    mutation DeleteInvoiceSend($id: String!) {
        delete_invoice_sends_by_pk(id: $id) {
            id
        }
    }
`;

export const RESYNC_INVOICE = gql`
    mutation ResyncInvoice($id: String!) {
        resyncInvoice(id: $id)
    }
`;
