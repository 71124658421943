import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { AccountingPageHeader } from '../components/accounting/accounting-page-header';
import { Commissions } from '../components/accounting/commissions';

export const AccountingCommissionsPage: React.FC = () => (
    <DocumentTitle title="Accounting - Commissions">
        <div id="container">
            <AccountingPageHeader />
            <div id="content" className="flex-fill">
                <Commissions accounting={true} />
            </div>
        </div>
    </DocumentTitle>
);
