import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import React from 'react';
import { getDefaultJobAndCompanyPromptData } from 'shared/common/llm';
import { SearchProject } from 'shared/models/search';
import { fullDate } from '../common/timestamp';
import { ProfileScoringForm, ProfileScoringFormData } from '../components/profile-scoring-form';
import { JobSearchScoring, SearchScore } from '../graphql/queries/profile-score';
import { useSnackbar } from '../hooks/use-snackbar';
import { useSearch } from './use-search';

const styles = (theme: Theme) => css`
    .MuiPaper-root {
        min-width: 720px;
    }

    .MuiDialogContent-root {
        border-top: 1px solid ${theme.palette.divider};
        border-bottom: 1px solid ${theme.palette.divider};
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .action-items {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .MuiIconButton-root {
            margin-right: 10px;
        }
    }

    .MuiInputBase-root.Mui-disabled {
        color: ${theme.palette.text.primary};
    }
`;

export const SearchProfilesScoreForm: React.FC<{
    data?: SearchScore;
    open: boolean;
    onClose: () => void;
}> = ({ data: initialData, open, onClose }) => {
    const theme = useTheme();
    const { data: search, job, sourcer, project, client, onCreateProfileScore, jobSearchScorings } = useSearch();
    const [data, setData] = React.useState<SearchScore | null>(initialData);
    const [saving, setSaving] = React.useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const { setSnackbar } = useSnackbar();
    const disabled = !!data?.id || saving;

    const defaultSearchScore: Partial<SearchScore> = React.useMemo(() => {
        const defaultData =
            project === SearchProject.HireflowV2
                ? {
                      additionalContext: '',
                      companyInformation: '',
                      jobDescription: sourcer?.aiSourcingStrategy?.trim()
                  }
                : {
                      additionalContext: job.scoringAIContext?.directions ?? '',
                      ...getDefaultJobAndCompanyPromptData(job, client)
                  };

        return {
            ...defaultData,
            searchId: search.id,
            title: ''
        };
    }, [search.id, job, client, project, sourcer?.aiSourcingStrategy]);

    const validateForm = () => {
        return data?.title?.trim()?.length > 0;
    };

    const handleGenerateScores = async () => {
        if (validateForm()) {
            setSaving(true);
            setSnackbar('Queuing profile scores generation');
            try {
                await onCreateProfileScore({ searchId: search.id, ...data });
                onClose();
            } catch (e) {
                setSnackbar('Error generating profile scores');
            } finally {
                setSaving(false);
            }
        }
    };

    const handleCopyScoringButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleCloseCopyMenu = () => {
        setMenuAnchorEl(null);
    };

    const handleCopyScoringSettings = (scoring: JobSearchScoring) => () => {
        const { title, companyInformation, jobDescription, additionalContext } = scoring;
        setData({
            ...data,
            additionalContext,
            companyInformation,
            jobDescription,
            title: `${title} (copy)`
        });
        setMenuAnchorEl(null);
    };

    const handleChange = (newData: ProfileScoringFormData) => {
        setData({ ...data, ...newData });
    };

    const generateButton = disabled ? null : (
        <Button onClick={handleGenerateScores} disabled={!validateForm()}>
            Generate Scores
        </Button>
    );

    const copyButton =
        !disabled && jobSearchScorings?.length > 0 ? (
            <Tooltip title="Copy from another scoring">
                <span>
                    <IconButton size="small" onClick={handleCopyScoringButtonClick}>
                        <FileCopyOutlined fontSize="small" />
                    </IconButton>
                </span>
            </Tooltip>
        ) : null;

    const copyScoringMenuItems = jobSearchScorings?.map((scoring) => (
        <MenuItem key={scoring.id} onClick={handleCopyScoringSettings(scoring)}>
            {scoring.title} - {scoring.user?.name} - ({fullDate(scoring.createdAt)})
        </MenuItem>
    ));
    const copyScoringMenu = (
        <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleCloseCopyMenu} keepMounted={true}>
            {copyScoringMenuItems}
        </Menu>
    );

    const formData = data?.id ? data : { ...defaultSearchScore, ...data };

    return (
        <Dialog open={open} maxWidth="lg" css={styles(theme)}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    {data?.id ? 'View Profile Scoring' : 'New Profile Scoring'}
                    {data?.id ? ` - ${data.title}` : ''}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <ProfileScoringForm data={formData} isEditable={!data?.id} onChange={handleChange} />
            </DialogContent>
            <DialogActions>
                <div className="action-items">
                    <div>
                        {copyButton}
                        {copyScoringMenu}
                    </div>
                    <div>
                        <Button onClick={onClose} disabled={saving}>
                            {data?.id ? 'Close' : 'Cancel'}
                        </Button>
                        {generateButton}
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    );
};
