import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { AccountingPageHeader } from '../components/accounting/accounting-page-header';
import { Invoices } from '../components/accounting/invoices';

export const AccountingInvoicesPage: React.FC = () => (
    <DocumentTitle title="Accounting - Invoices">
        <div id="container">
            <AccountingPageHeader />
            <div id="content" className="flex-fill">
                <Invoices />
            </div>
        </div>
    </DocumentTitle>
);
