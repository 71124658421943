import * as React from 'react';

import { Header } from '../header';
import { HeaderNavigationSelect } from '../header-navigation-select';

export type AccountingPageType = 'Revenue' | 'Invoices' | 'Bank Transactions' | 'Commissions';

export const accountingPageRoutes: Array<[AccountingPageType, string]> = [
    ['Revenue', '/accounting/revenue'],
    ['Invoices', '/accounting/invoices'],
    ['Bank Transactions', '/accounting/bank-transactions'],
    ['Commissions', '/accounting/commissions']
];

export const AccountingPageHeader: React.FC<{
    actions?: JSX.Element[];
}> = ({ actions }) => {
    const titleDropdown = <HeaderNavigationSelect routes={accountingPageRoutes} />;
    return <Header title={titleDropdown} actions={actions} />;
};
