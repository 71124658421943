import { gql } from '@apollo/client';

import { ModelParameters } from 'shared/common/llm';

export interface ScoreEvalReferenceCandidate {
    personId: string;
    jobId: string;
    progressScore: number;
    candidate: {
        stage: {
            name: string;
            label: string;
        };
        assignee: {
            name: string;
            id: string;
        };
        person: {
            name: string;
        };
        job: {
            title: string;
            client: {
                name: string;
            };
        };
        lastStageChangedAt: number;
    };
}

export interface ScoreEvalCandidates {
    id: string;
    profileScores: Array<{
        id: string;
        personId: string;
        jobId: string;
        searchProfilesScoreId: string;
        searchId: string;
        score: number;
        scoreExplanation: string;
        generationInfo: {
            resultsFile?: string;
            episodeId?: string;
            inferenceId?: string;
        };
        candidate: {
            personId: string;
            jobId: string;
            progressScore: number;
            stage: {
                name: string;
                label: string;
            };
            assignee: {
                name: string;
                id: string;
            };
            person: {
                name: string;
            };
            job: {
                title: string;
                client: {
                    name: string;
                };
            };
            lastStageChangedAt: number;
        };
    }>;
}

export interface CandidateScoreEval {
    id: string;
    title: string;
    startedAt: number;
    completedAt: number;
    failedAt: number;
    systemPrompt: string;
    userPromptTemplate: string;
    modelParameters: ModelParameters;
    referenceScoringId: string;
}

export const SCORE_EVAL_REFERENCE_CANDIDATES = gql`
    query ScoresEvalReferenceCandidates {
        candidates: candidate_scores_evals_reference {
            id
            personId
            jobId
            progressScore
            candidate {
                stage: job_stage {
                    name
                    label
                }
                assignee: userByAssignee {
                    name(path: "full")
                    id
                }
                person {
                    name(path: "full")
                }
                job {
                    id
                    title
                    client {
                        id
                        name
                    }
                }
                lastStageChangedAt
            }
        }
    }
`;

export const SCORE_EVALS = gql`
    query CandidateScoresEvals {
        evals: candidate_scores_evals {
            id
            title
            startedAt
            completedAt
            failedAt
            systemPrompt
            userPromptTemplate
            modelParameters
        }
    }
`;

export const CREATE_SCORE_EVAL = gql`
    mutation CreateCandidateScoresEval($data: candidate_scores_evals_insert_input!) {
        insert_candidate_scores_evals_one(object: $data) {
            id
        }
    }
`;

export const SCORE_EVAL_CANDIDATES = gql`
    query ScoresEvalCandidates($id: String!) {
        eval: candidate_scores_evals_by_pk(id: $id) {
            id
            profileScores {
                id
                personId
                jobId
                searchProfilesScoreId
                searchId
                score
                scoreExplanation
                generationInfo
                candidate {
                    personId
                    jobId
                    progressScore
                    stage: job_stage {
                        name
                        label
                    }
                    assignee: userByAssignee {
                        name(path: "full")
                        id
                    }
                    person {
                        name(path: "full")
                    }
                    job {
                        id
                        title
                        client {
                            id
                            name
                        }
                    }
                    lastStageChangedAt
                }
            }
        }
    }
`;
