import { gql } from '@apollo/client';

import { SequenceData } from 'shared/models/sequence';

export type Sequence = SequenceData;

export const SEQUENCES = gql`
    query Sequences($jobId: String!) {
        sequences(where: { jobId: { _eq: $jobId } }, order_by: { createdAt: desc }) {
            id
            jobId
            title
            type
            stages
            cc
            bcc
            weekDaysOnly
            modifiedAt
            createdAt
            archived
        }
    }
`;

export const CREATE_SEQUENCE = gql`
    mutation createSequence($sequence: sequences_insert_input!) {
        insert_sequences(objects: [$sequence]) {
            affected_rows
        }
    }
`;

export const UPDATE_SEQUENCE = gql`
    mutation UpdateSequence($id: String!, $sequence: sequences_insert_input!) {
        update_sequences(where: { id: { _eq: $id } }, _set: { archived: true }) {
            affected_rows
        }
        insert_sequences(
            objects: [$sequence]
            on_conflict: { constraint: sequences_jobId_contentHash_key, update_columns: [title, archived] }
        ) {
            affected_rows
        }
    }
`;

export const UPDATE_SEQUENCE_STATUS = gql`
    mutation UpdateSequenceStatus($id: String!, $archive: Boolean!) {
        update_sequences(where: { id: { _eq: $id } }, _set: { archived: $archive }) {
            affected_rows
        }
    }
`;

export const GET_SAMPLE_OUTREACH_PREVIEW = gql`
    query GetSampleOutreachPreview($args: SampleOutreachInput!) {
        sampleOutreach: getSampleOutreach(args: $args) {
            profile
            stages {
                subject
                body
            }
            systemPrompt
            userPrompt
            requestParameters
            episodeId
            inferenceId
        }
    }
`;

export const GENERATE_OUTREACH_TEMPLATE = gql`
    mutation GenerateOutreachTemplate($jobId: String!) {
        generateOutreachTemplate(jobId: $jobId)
    }
`;
