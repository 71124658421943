import { css } from '@emotion/core';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import * as React from 'react';

import { downloadFile } from '../actions';
import { tableBorderColor } from '../common/css-variables';
import { config } from '../config';
import { useReduxDispatch, useReduxState } from '../hooks/use-redux';
import { Pdf } from './pdf';

const styles = (width: string) => css`
    .MuiDialog-paper {
        width: ${width};

        .MuiDialogContent-root {
            padding: 0;
            border-bottom: 1px solid ${tableBorderColor};
        }

        .pdf-container,
        .google-docs-container {
            height: 80vh;
            overflow: hidden;
        }

        .audio-container,
        .video-container {
            margin: 20px;
        }

        .audio-container {
            audio {
                width: 400px;
            }
        }

        .video-container {
            video {
                max-width: 100%;
                max-height: 70vh;
            }
        }
    }
`;

export const FileDownloadLink: React.FC<{
    path: string;
    filename: string;
}> = ({ path, filename, children }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const pendingRequests = useReduxState((state) => state.pendingRequests);
    const dispatch = useReduxDispatch();

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (path) {
            setDialogOpen(true);
        }
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleDownloadClick = () => {
        dispatch(downloadFile(path, filename));
    };

    const inProgress = pendingRequests.has(`download-file-${path}`);
    let dialog;
    if (dialogOpen) {
        const publicUrl = `${config.FilesHost}/${path.split('/').map(encodeURIComponent).join('/')}`;
        const actions = [
            <Button onClick={handleCloseDialog} key="close">
                Close
            </Button>,
            <Button onClick={handleDownloadClick} key="download" disabled={inProgress}>
                Download
            </Button>
        ];

        const preview = path.match(/\.pdf$/i) ? (
            <div className="pdf-container">
                <Pdf url={publicUrl} height="100%" width="100%" />
            </div>
        ) : path.match(/\.mp3$/i) ? (
            <div className="audio-container">
                <audio controls={true} src={publicUrl}>
                    Audio playback not supported
                </audio>
            </div>
        ) : path.match(/\.mp4$/i) ? (
            <div className="video-container">
                <video controls={true} src={publicUrl}>
                    Video playback not supported
                </video>
            </div>
        ) : (
            <div className="google-docs-container">
                <iframe
                    src={`https://docs.google.com/viewer?embedded=true&url=${publicUrl}`}
                    frameBorder="0"
                    style={{ width: '100%', height: '100%' }}
                />
            </div>
        );

        const width = path.match(/\.(mp3|mp4)$/i) ? 'auto' : '70%';

        dialog = (
            <Dialog css={styles(width)} open={true} onClose={handleCloseDialog} maxWidth="md">
                <DialogContent>{preview}</DialogContent>
                <DialogActions>{actions}</DialogActions>
            </Dialog>
        );
    }

    return (
        <div>
            <div className={`file-download-link ${inProgress || !path ? 'disabled' : ''}`} onClick={handleClick}>
                {children}
            </div>
            {dialog}
        </div>
    );
};
