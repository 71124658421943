import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { AccountingPageHeader } from '../components/accounting/accounting-page-header';
import { BankTransactions } from '../components/accounting/bank-transactions';

export const AccountingBankTransactionsPage: React.FC = () => (
    <DocumentTitle title="Bank Transactions">
        <div id="container">
            <AccountingPageHeader />
            <div id="content" className="flex-fill">
                <BankTransactions />
            </div>
        </div>
    </DocumentTitle>
);
