import { useQuery } from '@apollo/client';
import { MenuItem, Select, useTheme } from '@material-ui/core';
import React from 'react';
import { SearchTextField } from '../../common/search-text-field';

import { createDuration, defaultPeriods, Duration } from '../../common/duration';
import { GET_INVOICES, Invoice } from '../../graphql/queries/accounting';
import { useLocalStorage } from '../../hooks/use-local-storage';
import { dataPanelContainerStyles, selectorStyles } from '../data-panel';
import { DurationSelector } from '../duration-selector';
import { NavigationSelect } from '../navigation-select';
import { accountingPageRoutes } from './accounting-page-header';
import { InvoicesDataPanel } from './invoices-data-panel';

export const Invoices: React.FC = () => {
    const theme = useTheme();
    const [duration, setDuration] = React.useState<Duration>(createDuration({ period: 'year', offset: 0 }));
    const [customerType, setCustomerType] = useLocalStorage<'staffing' | 'permanent' | 'all'>(
        'accounting-invoices-customer-type',
        'all'
    );
    const [searchText, setSearchText] = React.useState('');
    const { data } = useQuery<
        { invoices: Invoice[] },
        { startTime: number; endTime: number; isStaffingCustomer: boolean[] }
    >(GET_INVOICES, {
        variables: {
            endTime: duration.end,
            isStaffingCustomer:
                customerType === 'staffing' ? [true] : customerType === 'permanent' ? [false] : [true, false],
            startTime: duration.start
        }
    });

    const handleCustomerTypeChange = (event: React.ChangeEvent<{ value: string }>) => {
        setCustomerType(event.target.value as 'staffing' | 'permanent' | 'all');
    };

    return (
        <div css={dataPanelContainerStyles(theme)}>
            <div className="selectors">
                <div css={selectorStyles}>
                    <SearchTextField value={searchText} onValueChange={setSearchText} variant="outlined" />
                    <NavigationSelect routes={accountingPageRoutes} variant="outlined" />
                    <Select
                        value={customerType}
                        onChange={handleCustomerTypeChange}
                        variant="outlined"
                        className="select-value"
                    >
                        <MenuItem value="staffing">Staffing</MenuItem>
                        <MenuItem value="permanent">Permanent</MenuItem>
                        <MenuItem value="all">All</MenuItem>
                    </Select>
                    <DurationSelector selected={duration} onSelect={setDuration} periods={defaultPeriods} />
                </div>
            </div>
            <InvoicesDataPanel
                invoices={data?.invoices}
                searchText={searchText}
                columns={['month', 'customer', 'invoiceNumber', 'amount', 'status', 'dueDate', 'bankPayment']}
            />
        </div>
    );
};
