import { css } from '@emotion/core';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    TextField,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import React from 'react';

const styles = (theme: Theme) => css`
    .MuiExpansionPanel-root {
        box-shadow: none;
        border: thin solid ${theme.palette.divider};

        &:not(:last-child) {
            border-bottom: none;
        }

        &.Mui-expanded {
            border-bottom: thin solid ${theme.palette.divider};
        }

        .MuiExpansionPanelSummary-root {
            background: ${theme.palette.action.hover};
            border-bottom: thin solid ${theme.palette.divider};
            margin-bottom: -1px;
            min-height: 56px;

            &.Mui-expanded {
                min-height: 56px;
            }
        }

        &:last-child .MuiExpansionPanelSummary-root {
            border-bottom: none;

            &.Mui-expanded {
                border-bottom: thin solid ${theme.palette.divider};
            }
        }

        .MuiExpansionPanelSummary-content {
            &.Mui-expanded {
                margin: 12px 0;
            }
        }

        .MuiExpansionPanelDetails-root {
            padding: ${theme.spacing(2)}px;
        }
    }
`;

export interface ProfileScoringFormData {
    title: string;
    companyInformation: string;
    jobDescription: string;
    additionalContext: string;
}

interface ProfileScoringFormProps {
    data: ProfileScoringFormData;
    isEditable?: boolean;
    onChange: (data: ProfileScoringFormData) => void;
}

export const ProfileScoringForm: React.FC<ProfileScoringFormProps> = ({ data, isEditable = true, onChange }) => {
    const theme = useTheme();
    const handleChange = (field: keyof ProfileScoringFormData) => (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
            ...data,
            [field]: event.target.value
        });
    };

    return (
        <div css={styles(theme)}>
            <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6" component="div">
                        Scoring Title
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <TextField
                        InputProps={{ disableUnderline: true }}
                        value={data.title}
                        placeholder="Title for profile scoring."
                        onChange={handleChange('title')}
                        fullWidth={true}
                        disabled={!isEditable}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel defaultExpanded={false}>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6" component="div">
                        Company Information
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <TextField
                        InputProps={{ disableUnderline: true }}
                        value={data.companyInformation}
                        placeholder="Company Information for profile scoring."
                        onChange={handleChange('companyInformation')}
                        fullWidth={true}
                        multiline={true}
                        disabled={!isEditable}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel defaultExpanded={false}>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6" component="div">
                        Job Description
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <TextField
                        InputProps={{ disableUnderline: true }}
                        value={data.jobDescription}
                        placeholder="Job Description for profile scoring."
                        onChange={handleChange('jobDescription')}
                        fullWidth={true}
                        multiline={true}
                        disabled={!isEditable}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6" component="div">
                        Additional Context
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <TextField
                        InputProps={{ disableUnderline: true }}
                        value={data.additionalContext}
                        placeholder="Additional Context for profile scoring."
                        onChange={handleChange('additionalContext')}
                        fullWidth={true}
                        multiline={true}
                        disabled={!isEditable}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
};
