import { css } from '@emotion/core';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    MenuItem,
    Slider,
    TextField,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { startCase } from 'lodash';
import React from 'react';

import { ModelParameters } from 'shared/common/llm';

const sliderStep = 0.01;

const styles = (theme: Theme) => css`
    .MuiExpansionPanel-root {
        box-shadow: none;
        border: thin solid ${theme.palette.divider};

        &:not(:last-child) {
            border-bottom: none;
        }

        &.Mui-expanded {
            border-bottom: thin solid ${theme.palette.divider};
        }

        .MuiExpansionPanelSummary-root {
            background: ${theme.palette.action.hover};
            border-bottom: thin solid ${theme.palette.divider};
            margin-bottom: -1px;
            min-height: 56px;

            &.Mui-expanded {
                min-height: 56px;
            }
        }

        &:last-child .MuiExpansionPanelSummary-root {
            border-bottom: none;

            &.Mui-expanded {
                border-bottom: thin solid ${theme.palette.divider};
            }
        }

        .MuiExpansionPanelSummary-content {
            &.Mui-expanded {
                margin: 12px 0;
            }
        }

        .MuiExpansionPanelDetails-root {
            padding: ${theme.spacing(2)}px;
        }
    }

    .model-params {
        flex: 1 1 auto;

        .MuiTextField-root {
            margin-bottom: 12px;
        }

        .model-params-slider {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            margin-top: 16px;
            margin-right: 5px;

            .label {
                color: ${theme.palette.primary.main};
            }
        }
    }
`;

export interface ProfileScoringEvalFormData {
    title: string;
    systemPrompt: string;
    userPromptTemplate: string;
    modelParameters: ModelParameters;
}

interface ProfileScoringEvalFormProps {
    data: ProfileScoringEvalFormData;
    isEditable?: boolean;
    onChange: (data: ProfileScoringEvalFormData) => void;
    modelOptions: string[];
}

export const ProfileScoringEvalForm: React.FC<ProfileScoringEvalFormProps> = ({
    data,
    isEditable = true,
    onChange,
    modelOptions
}) => {
    const theme = useTheme();

    const handleChange =
        (field: keyof Omit<ProfileScoringEvalFormData, 'modelParameters'>) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange({
                ...data,
                [field]: event.target.value
            });
        };

    const handleModelParamsChange =
        (field: keyof Omit<ModelParameters, 'model'>) =>
        (_: React.ChangeEvent<HTMLInputElement>, newValue: number | number[]) => {
            onChange({
                ...data,
                modelParameters: { ...data.modelParameters, [field]: newValue }
            });
        };

    const handleModelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
            ...data,
            modelParameters: { ...data.modelParameters, model: event.target.value }
        });
    };

    const modelOptionsMenuItems = Array.from(
        new Set([...(data?.modelParameters?.model ? [data.modelParameters.model] : []), ...modelOptions])
    ).map((m) => (
        <MenuItem key={m} value={m}>
            {m}
        </MenuItem>
    ));

    const numericFields: Array<{
        field: keyof Omit<ModelParameters, 'model'>;
        max?: number;
        step?: number;
        defaultValue?: number;
    }> = [
        { field: 'top_p' },
        { field: 'max_tokens', step: 1, max: 16384, defaultValue: 1 },
        { field: 'temperature' },
        { field: 'presence_penalty' },
        { field: 'frequency_penalty' }
    ];

    const model = data?.modelParameters?.model;
    const filteredFields = model?.match(/^claude/)
        ? numericFields.filter(({ field }) => field === 'temperature' || field === 'max_tokens')
        : numericFields;

    const sliders = filteredFields.map(({ field, max, step, defaultValue }) => {
        const value = data?.modelParameters?.[field] ?? defaultValue ?? 0;
        return (
            <div key={field}>
                <div className="model-params-slider">
                    <div className="label">{startCase(field)}</div>
                    <div className="value">{value}</div>
                </div>
                <Slider
                    value={value}
                    onChange={handleModelParamsChange(field)}
                    min={0}
                    max={max ?? 1}
                    step={step ?? sliderStep}
                    disabled={!isEditable}
                />
            </div>
        );
    });

    return (
        <div css={styles(theme)}>
            <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6" component="div">
                        Eval Title
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <TextField
                        InputProps={{ disableUnderline: true }}
                        value={data.title}
                        placeholder="Title for the eval"
                        onChange={handleChange('title')}
                        fullWidth={true}
                        disabled={!isEditable}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel defaultExpanded={false}>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6" component="div">
                        System Prompt Template
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <TextField
                        InputProps={{ disableUnderline: true }}
                        value={data.systemPrompt}
                        placeholder="System prompt template for AI to use when generating a response."
                        onChange={handleChange('systemPrompt')}
                        fullWidth={true}
                        multiline={true}
                        disabled={!isEditable}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel defaultExpanded={false}>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6" component="div">
                        User Prompt Template
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <TextField
                        InputProps={{ disableUnderline: true }}
                        value={data.userPromptTemplate}
                        placeholder="User prompt template for AI to use when generating a response."
                        onChange={handleChange('userPromptTemplate')}
                        fullWidth={true}
                        multiline={true}
                        disabled={!isEditable}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel defaultExpanded={false}>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6" component="div">
                        Model Parameters
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className="model-params">
                        <TextField
                            label="Model"
                            fullWidth={true}
                            value={data?.modelParameters?.model ?? ''}
                            onChange={handleModelChange}
                            select={true}
                            disabled={!isEditable}
                        >
                            {modelOptionsMenuItems}
                        </TextField>
                        {sliders}
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
};
