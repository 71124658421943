import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { IconButton, Tooltip } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { startCase } from 'lodash';
import React, { useState } from 'react';

import { hasJobPermission } from 'shared/models/job';
import { ScheduledOutreachData } from 'shared/models/scheduled-outreach';

import {
    JOB_OUTREACH_CHECKS,
    JobOutreachCheckRecord,
    OutreachCandidates,
    READY_CANDIDATES,
    REVIVAL_CANDIDATES,
    SCHEDULE_OUTREACH
} from '../graphql/queries/outreach';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { JobStatus } from '../state';
import { OutreachSendDialog } from './outreach-send-dialog';

export const CandidatesOutreach: React.FC<{
    client: { name: string };
    jobId: string;
    dailyEmailLimit: number;
    status: JobStatus;
    iconColor?: string;
    iconSize?: 'small';
}> = ({ client, jobId, dailyEmailLimit, status, iconColor, iconSize }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const { user } = useSession();
    const { setAlert } = useModal();
    const [revival, setRevival] = useState(false);
    const { data: jobData } = useQuery<
        {
            job: JobOutreachCheckRecord;
        },
        { jobId: string }
    >(JOB_OUTREACH_CHECKS, { variables: { jobId } });
    const [fetchReadyCandidates, { data: readyCandidates }] = useLazyQuery<
        OutreachCandidates,
        { jobId: string; assignee: string }
    >(READY_CANDIDATES, { fetchPolicy: 'network-only' });
    const [fetchRevivalCandidates, { data: revivalCandidates }] = useLazyQuery<
        OutreachCandidates,
        { jobId: string; reason: string }
    >(REVIVAL_CANDIDATES, { fetchPolicy: 'network-only' });
    const [scheduleOutreach] = useMutation<{}, { outreach: Partial<ScheduledOutreachData> }>(SCHEDULE_OUTREACH);
    const { setSnackbar } = useSnackbar();

    const handleCloseOutreach = () => {
        setDialogOpen(false);
    };

    if (!jobData?.job) return null;

    const hasSendOutreachPermission = hasJobPermission(jobData?.job?.recruiterPermissions?.sendOutreach, user.id);

    const checkJobDetailsExistence = () => {
        if (jobData.job.jobType === 'S') return true;

        if (!jobData.job.onePager) {
            setAlert('One pager missing', 'Account manager needs to create the one pager before outreach can be sent');
            return false;
        }

        const job = jobData.job;
        const jobDescription = job.description;

        const requiredJobFields: Array<keyof JobOutreachCheckRecord> = [
            'placementFees',
            'recruiterPercentage',
            'accountManagerPercentage'
        ];
        const requiredJobDescriptionFields: Array<keyof JobOutreachCheckRecord['description']> = [
            'compensation',
            'description',
            'hiringManager',
            'visa'
        ];
        const missingJobFields = requiredJobFields.filter(
            (field) => job[field] === undefined || job[field] === '' || job[field] === null
        );
        const missingJobDescriptionFields = requiredJobDescriptionFields.filter(
            (field) =>
                jobDescription[field] === undefined || jobDescription[field] === '' || jobDescription[field] === null
        );
        const missingFields = [...missingJobFields, ...missingJobDescriptionFields];
        if (missingFields.length > 0) {
            const list = missingFields.map((field) => <li key={field}>{startCase(field)}</li>);
            const description = (
                <div>
                    <ul>{list}</ul>
                    <div style={{ marginTop: '25px' }}>
                        <a href={`/job/${jobId}/edit`} target="_blank">
                            Edit Job Settings
                        </a>
                    </div>
                </div>
            );
            setAlert('Job details missing fields', description);
            return false;
        }
        return true;
    };

    const handleOpenOutreach = () => {
        if (checkJobDetailsExistence()) {
            fetchReadyCandidates({ variables: { jobId, assignee: user.id } });
            setDialogOpen(true);
        }
    };

    const handleRevivalSelect = (revivalActive: boolean, reason: string) => {
        setRevival(revivalActive);
        if (revivalActive) {
            fetchRevivalCandidates({ variables: { jobId, reason } });
        }
    };

    const handleSend = async (data: {
        limit: number;
        revivalReason?: string;
        scheduledAt: number;
        sequenceId: string;
    }) => {
        setDialogOpen(false);
        setSnackbar('Scheduling outreach');
        const { limit, revivalReason, scheduledAt, sequenceId } = data;
        await scheduleOutreach({
            variables: { outreach: { limit, scheduledAt, revivalReason, sequenceId, jobId, userId: user.id } }
        });
        setSnackbar('Outreach scheduled');
    };

    const counts = revival ? revivalCandidates : readyCandidates;
    const availableCount = counts?.counts.aggregate.count;
    const person = counts?.candidates[0]?.person;
    const phoneCallSchedulingLink = counts?.candidates[0]?.assignee.calendarLink;
    const dialog = dialogOpen ? (
        <OutreachSendDialog
            client={client}
            jobId={jobId}
            sampleRecipient={person}
            availableCount={availableCount}
            dailyEmailLimit={dailyEmailLimit}
            dailyPerUserEmailLimit={jobData.job.dailyPerUserEmailLimit}
            onClose={handleCloseOutreach}
            onSend={handleSend}
            onRevivalSelect={handleRevivalSelect}
            phoneCallSchedulingLink={phoneCallSchedulingLink}
        />
    ) : null;

    const disallowed = !hasSendOutreachPermission;
    const disabled = status !== JobStatus.Active || dailyEmailLimit === 0 || disallowed;
    const tooltip = disallowed
        ? 'Job outreach disallowed'
        : dailyEmailLimit <= 0
          ? 'Outreach set to zero'
          : status === JobStatus.Active
            ? 'Send Outreach'
            : 'Job Not Active';
    return (
        <div>
            <Tooltip title={tooltip}>
                <span>
                    <IconButton onClick={handleOpenOutreach} disabled={disabled} size={iconSize}>
                        <Send htmlColor={disabled ? undefined : iconColor} fontSize={iconSize} />
                    </IconButton>
                </span>
            </Tooltip>
            {dialog}
        </div>
    );
};
