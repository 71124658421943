import { useQuery } from '@apollo/client';
import { MenuItem, Select, useTheme } from '@material-ui/core';
import { escapeRegExp } from 'lodash';
import moment from 'moment';
import * as React from 'react';

import { internalClientIds, JobType } from 'shared/common/job-constants';
import { currencyFormat } from 'shared/common/string-format-utils';
import { feeTypeLabel } from 'shared/models/fee';
import { hasRole } from 'shared/models/user';

import { feeStatus, getFeeStatusLabel } from '../../common/billing';
import { createDuration, defaultPeriods, Duration } from '../../common/duration';
import { PageDialogLink } from '../../common/page-dialog-link';
import { SearchTextField } from '../../common/search-text-field';
import { JobEdit } from '../../containers/job-edit';
import { Fee, REVENUE } from '../../graphql/queries/billing';
import { useSlides } from '../../hooks/use-candidate-slides';
import { FeesProvider, useFees } from '../../hooks/use-fees';
import { useLocalStorage } from '../../hooks/use-local-storage';
import { useSession } from '../../hooks/use-session';
import { ClientInvoicesFees } from '.././client-invoices-fees';
import { dataPanelContainerStyles, selectorStyles } from '.././data-panel';
import { CellValue, DataPanel, DataPanelColumn } from '.././data-panel';
import { DurationSelector } from '.././duration-selector';
import { JobsTypeSelector, jobTypesForFilter } from '.././jobs-type-selector';
import { NavigationSelect } from '../navigation-select';
import { accountingPageRoutes } from './accounting-page-header';

type Column = 'month' | 'candidate' | 'client' | 'job' | 'type' | 'amount' | 'recruiter' | 'accountManager' | 'status';

const columns: Array<DataPanelColumn<Column>> = [
    { id: 'month', label: 'Month' },
    { id: 'client', label: 'Client' },
    { id: 'job', label: 'Job' },
    { id: 'type', label: 'Type' },
    { id: 'candidate', label: 'Candidate' },
    { id: 'recruiter', label: 'Recruiter' },
    { id: 'accountManager', label: 'Account Manager' },
    { id: 'amount', label: 'Amount' },
    { id: 'status', label: 'Status' }
];

const FeeCell: React.FC<{ value: CellValue; fee: Fee }> = ({ value, fee }) => {
    const { onEditFee } = useFees();

    const handleClick = () => {
        onEditFee?.(fee);
    };

    return (
        <span className="clickable-cell" onClick={handleClick}>
            ${(value as number).toLocaleString()}
        </span>
    );
};

export const Revenue = (): JSX.Element => {
    const theme = useTheme();
    const { setList } = useSlides();
    const [duration, setDuration] = React.useState<Duration>(createDuration({ period: 'year', offset: 0 }));
    const [jobTypes, setJobTypes] = useLocalStorage<JobType[]>('fees-job-types', jobTypesForFilter.get('all'));
    const [prepaymentsIncluded, setPrepaymentsIncluded] = useLocalStorage<0 | 1>('fees-prepayments-included', 0);
    const [searchText, setSearchText] = React.useState('');
    const { userPermissions } = useSession();
    const disabled = !hasRole(userPermissions, 'billing_admin');

    const { data, refetch } = useQuery<
        { fees: Fee[] },
        { jobType: string[]; startTime: number; endTime: number; excludeClientIds: string[] }
    >(REVENUE, {
        variables: {
            endTime: duration.end,
            excludeClientIds: internalClientIds,
            jobType: jobTypes,
            startTime: duration.start
        }
    });

    const handleSelectPerson = (personId: string, jobId: string) => () => {
        const list = data?.fees
            .filter((f) => !!f.jobId && !!f.personId)
            .map((f) => ({ jobId: f.jobId, personId: f.personId }));
        const selected = { jobId, personId };
        setList(list, selected);
    };

    const handleChangePrepayments = (event: React.ChangeEvent<{ value: 0 | 1 }>) => {
        setPrepaymentsIncluded(event.target.value as 0 | 1);
    };

    const getCellValue = (column: Column) => (fee: Fee) => {
        switch (column) {
            case 'month':
                return fee.type === 'placement' ? fee.dueAt : fee.incurredAt;
            case 'client':
                return fee.client?.name || '-';
            case 'job':
                return fee.job?.title || '-';
            case 'candidate':
                return fee.person?.name || '-';
            case 'type':
                return feeTypeLabel(fee.type);
            case 'amount':
                return fee.amount;
            case 'recruiter':
                return fee.recruiter?.name || '-';
            case 'accountManager':
                return fee.accountManager?.name || '-';
            case 'status':
                return getFeeStatusLabel(feeStatus(fee));
        }
    };

    const getCellRenderer = {
        amount: (value: CellValue, fee: Fee) => <FeeCell value={value} fee={fee} />,
        candidate: (value: CellValue, fee: Fee) => (
            <span className="clickable-cell" onClick={handleSelectPerson(fee.personId, fee.jobId)}>
                {value}
            </span>
        ),
        client: (value: CellValue, fee: Fee) => (
            <PageDialogLink
                Component={ClientInvoicesFees}
                componentProps={{ clientId: fee.client?.id, activeTab: 'fees' }}
                url={`/client/${fee.client?.id}/fees`}
            >
                <a href={`/client/${fee.client?.id}/fees`}>{value}</a>
            </PageDialogLink>
        ),
        job: (value: CellValue, fee: Fee) => (
            <PageDialogLink Component={JobEdit} componentProps={{ id: fee.jobId }} url={`/job/${fee.jobId}/edit`}>
                <a href={`/job/${fee.jobId}/edit`}>{value}</a>
            </PageDialogLink>
        ),
        month: (value: CellValue, _1: Fee) => moment(value as number).format('MMM YYYY')
    };

    const getCellFilterValue = {
        month: (value: CellValue) => moment(value as number).format('MMM YYYY')
    };

    const renderSummary = React.useCallback(
        (fees: Fee[]) => {
            const total = fees.reduce((acc, fee) => acc + fee.amount, 0);
            return data?.fees ? <div>Total: {currencyFormat(total)}</div> : null;
        },
        [data?.fees]
    );

    const rowFilter = React.useCallback(
        (fee: Fee) => {
            const searchRegex = searchText ? new RegExp(escapeRegExp(searchText), 'i') : undefined;
            return (
                (fee.type !== 'prepayment' || prepaymentsIncluded === 1) &&
                (!searchText ||
                    !!(
                        fee.recruiter?.name?.match(searchRegex) ||
                        fee.client?.name?.match(searchRegex) ||
                        fee.job?.title?.match(searchRegex) ||
                        fee.accountManager?.name?.match(searchRegex) ||
                        getFeeStatusLabel(feeStatus(fee))?.match(searchRegex) ||
                        feeTypeLabel(fee.type)?.match(searchRegex)
                    ))
            );
        },
        [searchText, prepaymentsIncluded]
    );

    return (
        <div css={dataPanelContainerStyles(theme)}>
            <div className="selectors">
                <div css={selectorStyles}>
                    <SearchTextField value={searchText} onValueChange={setSearchText} variant="outlined" />
                    <NavigationSelect routes={accountingPageRoutes} variant="outlined" />
                    <Select value={prepaymentsIncluded} onChange={handleChangePrepayments} variant="outlined">
                        <MenuItem value={1}>Include Prepayments</MenuItem>
                        <MenuItem value={0}>Exclude Prepayments</MenuItem>
                    </Select>
                    <DurationSelector selected={duration} onSelect={setDuration} periods={defaultPeriods} />
                    <JobsTypeSelector selected={jobTypes} onChange={setJobTypes} />
                </div>
            </div>
            <div className="data-panel">
                <FeesProvider data={data?.fees} disabled={disabled} refetch={refetch}>
                    <DataPanel
                        columns={columns}
                        data={data?.fees}
                        getCellValue={getCellValue}
                        getCellRenderer={getCellRenderer}
                        getCellFilterValue={getCellFilterValue}
                        sortableColumns={['month', 'amount', 'client', 'type', 'recruiter', 'accountManager', 'status']}
                        filterableColumns={['month', 'type', 'recruiter', 'accountManager', 'client', 'job', 'status']}
                        initialSortColumn="date"
                        initialSortDirection="desc"
                        renderSummary={renderSummary}
                        rowFilter={rowFilter}
                    />
                </FeesProvider>
            </div>
        </div>
    );
};
