// cspell:ignore Accountmanagerid
import { gql } from '@apollo/client';

import { CandidateDisqualReason } from 'shared/models/job-stages';
export interface CandidateScore {
    id: string;
    score: number;
    scoreExplanation: string;
    generationInfo: {
        resultsFile?: string;
        episodeId?: string;
        inferenceId?: string;
    };
    searchProfilesScoreId: string;
    user: {
        id: string;
        name: string;
    };
    createdAt: number;
}

export interface CandidateUsers {
    assignee: {
        id: string;
        name: string;
    };
    accountManager: {
        id: string;
        name: string;
    };
}

export interface Candidate extends CandidateUsers {
    personId?: string;
    jobId?: string;
    lastStageChangedAt?: number;
    disqualifiedAt?: number;
    disqualReason?: CandidateDisqualReason;
    job: {
        title: string;
        client: {
            id: string;
            name: string;
        };
    };
    person?: {
        name: string;
    };
    scores?: Array<{
        score: number;
        searchProfilesScoreId: string | null;
        createdAt: number;
    }>;
}

const candidateFields = gql`
    fragment candidateFields on candidates {
        personId
        jobId
        lastStageChangedAt
        job {
            title
            client {
                id
                name
            }
        }
        accountManager: userByAccountmanagerid {
            id
            name(path: "full")
        }
        assignee: userByAssignee {
            name(path: "full")
            id
        }
        person {
            name(path: "full")
        }
        scores {
            id
            score
            scoreExplanation
            generationInfo
            searchProfilesScoreId
            user {
                id
                name(path: "full")
            }
            createdAt
        }
        disqualifiedAt
        disqualReason
    }
`;

export const STAGE_CANDIDATES = gql`
    ${candidateFields}
    query StageCandidates($jobType: [String!], $excludeClientIds: [String!], $stages: [String!]) {
        candidates(
            where: {
                job: { jobType: { _in: $jobType }, clientId: { _nin: $excludeClientIds }, status: { _in: [1, 2] } }
                stage: { _in: $stages }
                disqualified: { _eq: false }
            }
            order_by: { lastStageChangedAt: desc }
        ) {
            ...candidateFields
        }
    }
`;

export const DISQUALIFIED_CANDIDATES = gql`
    ${candidateFields}
    query DisqualifiedCandidates(
        $jobType: [String!]
        $excludeClientIds: [String!]
        $stages: [String!]
        $startTime: bigint!
        $endTime: bigint!
    ) {
        candidates(
            where: {
                job: { jobType: { _in: $jobType }, clientId: { _nin: $excludeClientIds } }
                stage: { _in: $stages }
                disqualified: { _eq: true }
                disqualifiedAt: { _gte: $startTime, _lte: $endTime }
            }
            order_by: { lastStageChangedAt: desc }
        ) {
            ...candidateFields
        }
    }
`;

export const CANDIDATE_USERS = gql`
    query CandidateUsers($personId: String!, $jobId: String!) {
        candidates(where: { personId: { _eq: $personId }, jobId: { _eq: $jobId } }) {
            assignee: userByAssignee {
                id
                name(path: "full")
            }
            accountManager: userByAccountmanagerid {
                id
                name(path: "full")
            }
        }
    }
`;

export interface CandidateCrosspitchJobsData {
    candidate: {
        cpJobScores: Array<{
            id: string;
            score: number;
            generationInfo: {
                resultsFile?: string;
                episodeId?: string;
                inferenceId?: string;
            };
            scoreExplanation: string;
            createdAt: number;
            personId: string;
            jobId: string;
            user: {
                id: string;
                name: string;
            };
            job: {
                id: string;
                title: string;
                client: {
                    id: string;
                    name: string;
                };
            };
        }>;
    };
}

export const GET_CANDIDATE_AUTOGENERATE_FEE_SETTING = gql`
    query GetCandidateAutoGenerateFeeSetting($personId: String!, $jobId: String!) {
        candidate: candidates_by_pk(personId: $personId, jobId: $jobId) {
            _id
            personId
            jobId
            autoGenerateFeeDisabled
        }
    }
`;

export const UPDATE_CANDIDATE = gql`
    mutation UpdateCandidate($personId: String!, $jobId: String!, $updates: candidates_set_input!) {
        update_candidates_by_pk(pk_columns: { personId: $personId, jobId: $jobId }, _set: $updates) {
            _id
            personId
            jobId
        }
    }
`;

export const CROSSPITCH_JOBS = gql`
    query CrosspitchJobs($personId: String!, $jobId: String!) {
        candidate: candidates_by_pk(personId: $personId, jobId: $jobId) {
            _id
            cpJobScores: crosspitch_scores(order_by: [{ score: desc }, { job: { createdAt: desc } }], limit: 5) {
                id
                score
                generationInfo
                scoreExplanation
                createdAt
                personId
                jobId
                job {
                    id
                    title
                    client {
                        id
                        name
                    }
                }
                user {
                    id
                    name(path: "full")
                }
                searchId
            }
        }
    }
`;

export const CP_JOB_DETAILS = gql`
    query CPJobDetails($jobId: String!) {
        job: jobs_by_pk(id: $jobId) {
            id
            title
            createdAt
            assignee: userByAssignee {
                id
                name(path: "full")
            }
            client {
                id
                name
                description
                hiredCandidates: candidates_aggregate(
                    where: { stage: { _eq: "hired" }, disqualified: { _eq: false } }
                ) {
                    aggregate {
                        count
                    }
                }
                salesPitch
                founders
                funding
                hq
                foundedDate
                teamSize
            }
            accountManager: userByAccountmanagerid {
                id
                name(path: "first")
            }
            jobType
            status
            description {
                id: jobId
                summary
                location
                minExperienceYears
                maxExperienceYears
                diversity
                linkToJobDescription
                visa
                compensation
                description
                interviewPrep
                hiringManager
                mustHaves
                niceToHaves
                responsibilities
            }
            discipline
            headcount
            recruiterPercentage
            placementFees
            accountManagerPercentage
        }
    }
`;
